export default {
    methods: {
        redirectCheck(noonaBusinesses = [], gotoDashboardOnAllChecks = false) {

            const allStoresAreLinked = noonaBusinesses.every(
                (item) => item.store_name !== null && item.store_name !== undefined
            );
            const preferenceIsSet = noonaBusinesses.every((item) =>
                item.booking_preference && item.booking_preference.booking_plan
                    ? true
                    : false
            );

            if (allStoresAreLinked === false) {
                this.$router.push({ name: "StoreSettings" });
            } else if (preferenceIsSet === false) {
                this.$router.push({ name: "BookingSettings" });
            } else if (gotoDashboardOnAllChecks) {
                this.$router.push({ name: "Appointments" });
            }
        }
    },
}