<template>
  <div>
    <trac-loading v-if="loading" />
    <div class="md:px-12 px-6 py-12 m-12 border rounded bg-white">
      <div class="text-center">
        <h1 class="font-bold mb-2">Online URL for Noona</h1>
        <p class="text-xs">
          Copy link and share with your customer for appointment booking
        </p>
      </div>
      <div
        class="flex items-center my-6 text-xs border rounded-md border-blue-600 md:w-3/5 w-full mx-auto"
      >
        <div class="flex-initial ml-4">{{ domainName }}/</div>
        <input :disabled="loading" v-model="slugName" class="flex-1" placeholder="customize here" />
        <button
          @click="saveSlug()"
          :disabled="!slugNameCheck.isValid"
          :class="{
            'bg-blue-700': slugNameCheck.isValid,
            'bg-gray-400': !slugNameCheck.isValid,
          }"
          class="smallest text-white p-4"
        >
          <span class="uppercase">Save</span>
        </button>
        <button
          @click="doCopy('xxxx')"
          class="smallest bg-blue-800 text-white p-4 rounded-r-md"
        >
          <span v-if="!copied" class="uppercase">Copy</span>
          <span v-else class="uppercase">Copied</span>
        </button>
      </div>
      <div
        v-if="!slugNameCheck.isValid"
        class="text-center text-xs text-red-500"
      >
        {{ slugNameCheck.message }}
      </div>
      <div class="flex justify-center gap-x-3 my-8">
        <trac-button
          @button-clicked="$router.push({ name: 'StoreSettings' })"
          variant="outline"
          class="uppercase"
        >
          Sync Stores
        </trac-button>
        <trac-button
          @button-clicked="$router.push({ name: 'BookingSettings' })"
          variant="outline"
          class="uppercase"
        >
          Booking Settings
        </trac-button>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../eventBus";
import redirectMixin from './redirectMixin';
export default {
  mixins: [redirectMixin],
  data() {
    return {
      copied: false,
      loading: false,
      slugName: "",
      domainName: process.env.VUE_APP_NOONA_STORE_URL
    };
  },
  created() {
    this.checkStatus();
  },
  methods: {
    checkStatus() {
      this.loading = true
      this.$store
        .dispatch("NOONA_CHECK_STATUS")
        .then((data) => {
          console.log("data resolved: ", data);
          if (data.status && data.data.connected) {
            const noonaBusinesses = data.data.noona_business || []
            const firstItem = noonaBusinesses[0] || {slug: null} // (noonaBusinesses[0] || { slug: null})
            this.slugName = firstItem.slug || null;
            this.redirectCheck(noonaBusinesses)
            this.loading = false
          } else {
            // take to login screen
            this.$router.push({ name: "Noona" });
          }
        })
        .catch((err) => {
          console.error('NOONA_CHECK_STATUS outcome error thread', err)
        this.$router.push({ name: "Noona" });
          this.loading = false;
          eventBus.$emit("trac-alert", {
            message: "An error occured. Please try again",
          });
        });
    },
    doCopy() {
      const codeString = `${this.domainName}/${this.slugName}`
      this.copied = true;
      this.copyToClipboard(codeString);
      const resetAfterSomeTime = setTimeout(() => {
        this.copied = !this.copied;
      }, 3000);
    },
    saveSlug() {
      const payload = {
        slug: this.slugName,
        booking_url: `tractionapp.co/${this.slugName}`,
      };
      this.loading = true;
      this.$store.dispatch("NOONA_SLUG", payload).then((data) => {
        if (data.status) {
          // toast message for success
          eventBus.$emit("trac-alert", {
            message: data.message,
          });
          this.loading = false;
          this.$TrackEvent('TA_MERCHANT_APP_NOONA_SAVESLUG')
        } else {
          // toast message for error message
          eventBus.$emit("trac-alert", {
            message: data.message,
          });
          this.loading = false;
        }
      });
    },
    copyToClipboard(codeString) {
      const onTheFlyTextArea = document.createElement("textarea");
      onTheFlyTextArea.value = codeString;
      document.body.appendChild(onTheFlyTextArea);
      onTheFlyTextArea.select();

      const copyOperation = document.execCommand("copy");
      if (copyOperation) {
        // console.log("Copied!");
        document.body.removeChild(onTheFlyTextArea);
        return Promise.resolve(true);
      } else {
        // console.warn("copy fail");
        return Promise.reject(new Error("Clipboard Copy Failed"));
      }
    },
  },
  computed: {
    slugNameCheck() {
      const regex = /^[a-z]+(-[a-z]+)*$/;
      return {
        isValid: regex.test(this.slugName) && (this.slugName || "").length >= 5,
        message:
          "Must contain only letters and/or dashes. Must have at least 5 characters",
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
.trim-box {
  background: #f6fcff;
  border: 0.3px dashed #253b95;
  border-radius: 5px;
}
</style>